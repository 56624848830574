import { FC } from 'react';
import CookieOutlinedIcon from '@mui/icons-material/CookieOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useStyles } from './styles';

type Props = {
  openSettings: () => void;
};

/** Cookie button to re-open cookies-settings */
const CookiesButton: FC<Props> = ({ openSettings }) => {
  const styles = useStyles();

  return (
    <Box sx={styles.buttonWrapper}>
      <Button sx={styles.button} onClick={openSettings} variant="large">
        <CookieOutlinedIcon />
      </Button>
    </Box>
  );
};

export default CookiesButton;
