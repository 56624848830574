import { useTranslation } from 'next-i18next';
import { getColorVariant } from '@utils/functions/isValidColor';
import { DrupalNode } from 'next-drupal';
import { EcosystemLinkItem, HeaderMenus, HeaderModel, HeaderTab, VerticalLinkListItem } from '@model/header.model';
import { getLinkData } from '@utils/functions/getLinkData';
import { absoluteUrl } from '@utils/functions/utils';
import { FeaturedItemListModel, FeaturedItemModel } from '@model/featured-item-list.model';
import { isExternal } from 'util/types';
import { useMemo } from 'react';
import { useRouter } from 'next/router';

interface UseHeaderMapperProps {
  backendData: any;
  node: DrupalNode;
}

const useHeaderMapper = ({ backendData, node }: UseHeaderMapperProps): HeaderModel => {
  const { t } = useTranslation();
  const router = useRouter();

  const colorVariant = router?.asPath?.includes('/video-search')
    ? 'dark'
    : getColorVariant(node.field_paragraphs[0]?.behavior_settings?.style_options?.color_scheme?.css_class);

  const ecosystemMenuColumns = useMemo(
    () =>
      backendData.ecosystemMenu?.at(0)?.items?.map((c) => ({
        title: c.title,
        weight: c.weight,
        items: c.items?.map((s) => ({
          title: s?.title,
          links: s?.items?.map((link) => getEcosystemLinksProps(link)),
        })),
      })),
    [backendData.ecosystemMenu]
  );

  const ecosystemMenuSections = useMemo(
    () => ecosystemMenuColumns.reduce((acc, val) => [...acc, ...(val?.items || [])], []),
    [ecosystemMenuColumns]
  );

  return {
    searchConfig: backendData.blockSearchConfig,
    menus: backendData.mainMenu.map((item) => getHeaderMenuProps(item)),
    colorVariant: colorVariant,
    ecosystem: {
      menuLabel: t('groupSites'),
      description: backendData.ecosystemMenu?.[0]?.description,
      title: backendData.ecosystemMenu?.[0]?.title,
      links: backendData.ecosystemMenu?.[0]?.items?.map((link) => getEcosystemLinksProps(link)),
      sections: ecosystemMenuSections,
      columns: ecosystemMenuColumns,
    },
    contactsLabel: backendData.ecosystemMenu?.[1]?.title,
    contactsUrl: backendData.ecosystemMenu?.[1]?.url,
    socialsMenu: {
      title: t('ecosystemMenuContactsTitle'),
      ...(!!backendData?.ecosystemMenu?.[1]?.items && {
        items: (backendData?.ecosystemMenu?.[1]?.items as DrupalNode[])?.map((e) => ({
          id: e?.id,
          url: e?.url,
          title: e?.title,
        })),
      }),
    },
  };
};

export default useHeaderMapper;

function getEcosystemLinksProps(link): EcosystemLinkItem {
  return {
    ...getLinkData(link),
    description: link.description,
  };
}

function getVerticalLinksListProps(link): VerticalLinkListItem {
  return {
    ...getLinkData(link),
    childrenLinks: link.items?.map((childenLink) => getVerticalLinksListProps(childenLink)),
  };
}

function getTabsProps(tabs): HeaderTab {
  return {
    tabLabel: tabs.title,
    items: tabs.items.map((tab) => {
      return {
        ...getLinkData(tab),
        iconName: tab?.options?.attributes?.menu_material_icons,
        ...(!tab?.options?.attributes?.menu_material_icons && {
          backgroundImage: {
            src: absoluteUrl(tab?.field_media?.field_media_image?.uri?.url),
            alt: tabs?.field_media?.name || 'image',
          },
        }),
      };
    }),
  };
}

function getFeaturedItemProps(item): FeaturedItemModel {
  return {
    title: item.title,

    cta: {
      url: item.path.alias,
      isExternal: false,
      target: '_self',
    },
    imageUrl: absoluteUrl(item.field_media?.field_media_image.uri.url),
    // iconName: item?.iconName,
  };
}

const resolveCtaUrl = (item) => {
  const uri = item?.field_link?.uri;
  const url = item?.field_link?.url;
  const isExternal = uri?.startsWith('http');

  if (isExternal) {
    return uri;
  }

  const internalUrl = url
    ? url.replace(process.env.NEXT_PUBLIC_DRUPAL_BASE_URL || '', '').replace('internal:', '')
    : '';
  return internalUrl;
};

const resolveFeaturedItems = (menu: DrupalNode) => {
  const viewItems = menu?.view?.results?.map((item) => getFeaturedItemProps(item)) || [];
  const fieldLinks =
    (menu?.field_media_links?.map((item) => {
      const isExternal = item?.field_link?.uri?.startsWith('http');
      const url = resolveCtaUrl(item);
      return {
        title: item?.name,
        cta: { url, isExternal, ...(isExternal && { target: '_blank' }) },
        imageUrl: absoluteUrl(item?.field_media_image?.uri?.url),
      };
    }) as FeaturedItemModel[]) || [];
  return { variant: 'project', items: [...fieldLinks, ...viewItems] } as FeaturedItemListModel;
};

function getHeaderMenuProps(menu): HeaderMenus {
  return {
    menuLabel: menu.title,
    ...(menu?.view_mode === 'tabs' && { iconName: 'auto_awesome' }),
    url: menu.url,
    title: menu.title,
    position: menu.field_position || 'before',
    description: menu.description,
    featuredItems: resolveFeaturedItems(menu),
    ...(menu.view_mode === 'default' && {
      verticalLinksList: menu.items?.map((link) => getVerticalLinksListProps(link)),
    }),
    ...(menu.view_mode === 'tabs' && { tabs: menu.items?.map((link) => getTabsProps(link)) }),
  };
}
