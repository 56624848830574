import React from 'react';
import HeaderContent from './HeaderContent/HeaderContent';
import { HeaderContextProvider } from './Header.context';
import { DrupalNode } from 'next-drupal';
import { LangData } from '@model/types';

type Props = { backendData: any; node?: DrupalNode; langData?: LangData[] };

export const Header = ({ backendData, node, langData }: Props) => {
  return (
    <HeaderContextProvider {...{ backendData, langData, node }}>
      <HeaderContent />
    </HeaderContextProvider>
  );
};
