import { useMemo, useState } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import Box from '@mui/material/Box';
// import Footer from '@organisms/Footer/Footer';
// import { Header } from '@organisms/Header/Header';
// import AnchorMenu from '@organisms/AnchorMenu/AnchorMenu';
// import FabParagraph from '../FabParagraph/FabParagraph';
// import LastUpdated from '@organisms/LastUpdated/LastUpdated';
import { Header } from '@organisms/Header/AltHeader/Header';
import CookiesManager from '@organisms/CookiesManager/CookiesManager';

const FabParagraph = dynamic(() => import('@organisms/FabParagraph/FabParagraph'));
const LastUpdated = dynamic(() => import('@organisms/LastUpdated/LastUpdated'));
const Footer = dynamic(() => import('@organisms/Footer/Footer'));
const AnchorMenu = dynamic(() => import('@organisms/AnchorMenu/AnchorMenu'));

const excludedFabPaths = ['/search-data'];

export function Layout({ children, menus, node, langData, hideFooter = false }) {
  const [toc, setToc] = useState(false);

  useMemo(() => {
    const hasToc = node.field_paragraphs.some((field) => {
      return field.behavior_settings?.style_options?.toc;
    });
    setToc(hasToc);
  }, [node.field_paragraphs]);

  const fab = menus?.blockFab[0];

  // TODO: Fixare lato drupal il valore che arriva
  const activeFab = +process.env.NEXT_PUBLIC_MULTISITE_CONTEXT_TAX === 28;

  // @TODO: Fix better header menu language change
  // Used to force menu content refresh with correct language
  const { locale } = useRouter();

  return (
    <Box component="main" display="flex" flexDirection="column">
      <Header key={locale} backendData={menus} node={node} langData={langData} />

      <Box flex={1}>
        {children}

        {node.field_flag && <LastUpdated node={node} />}
      </Box>

      {!hideFooter && <Footer menus={menus} />}

      {toc && <AnchorMenu node={node} />}

      <CookiesManager />

      {activeFab && fab && !excludedFabPaths.includes(node.path.alias) && <FabParagraph fieldParagraph={fab} />}
    </Box>
  );
}

export default Layout;
