import theme from '@mui/theme';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CustomButton from '@atoms/CustomButton/CustomButton';
import { useTranslation } from 'next-i18next';
import { useFormContext } from 'react-hook-form';

const CookieSettingsHeader = () => {
  const { t } = useTranslation();

  const { reset } = useFormContext();

  const acceptAllHandler = () => {
    reset({
      facebook: true,
      twitter: true,
      youtube: true,
      linkedin: true,
      article: true,
    });
  };

  const rejectAllHandler = () => {
    reset({
      facebook: false,
      twitter: false,
      youtube: false,
      linkedin: false,
      article: false,
    });
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexWrap="wrap"
      gap={theme.spacing(30)}
      padding={theme.spacing(32, 60)}
      borderBottom=".1rem solid"
      borderColor="secondary.200"
    >
      <Typography flexGrow={1} textAlign={{ xs: 'center', lg: 'left' }} component="h3" whiteSpace="nowrap" variant="h4">
        {t('cookieSettings.title')}
      </Typography>
      <Box display="flex" justifyContent="center" flexDirection="row" gap={theme.spacing(16)}>
        <CustomButton variant="small" color="secondary" onClick={acceptAllHandler}>
          {t('cookieSettings.acceptAll')}
        </CustomButton>
        <CustomButton variant="small" color="secondary" onClick={rejectAllHandler}>
          {t('cookieSettings.rejectAll')}
        </CustomButton>
      </Box>
    </Box>
  );
};

export default CookieSettingsHeader;
