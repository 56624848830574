export function absoluteUrl(input: string) {
  if (!input) return '';

  return `${process.env.NEXT_PUBLIC_DRUPAL_BASE_URL}${input}`;
}

export function frontendUrl(input: string) {
  return `${process.env.NEXT_PUBLIC_BASE_URL}${input}`;
}

export function getUri(input: string) {
  return input.replace('internal:', '/').replace('public:', '/').replace('entity:', '/');
}

export const transformTitleId = (title) => {
  const ids = title.toLowerCase().split(' ');
  const id = ids.length > 1 ? `${ids[0]}_${ids[1]}` : ids[0];
  return id;
};

export const containsNumber = (str) => {
  return /\d/.test(str);
};

export const slugify = (str) =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');

export const isValidURL = (url) => {
  try {
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
};

export const removeHtmlTags = (htmlString) => {
  const doc = new DOMParser().parseFromString(htmlString, 'text/html');
  return doc.body.textContent || '';
};

export const GLOBAL_QUERY_STALE_TIME = 100000;

export const extractVideoIdFromUrlYoutube = (url: string) => {
  if (!url) return '';
  const videoIdMatch = url.match(
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
  );
  return videoIdMatch ? videoIdMatch[1] : '';
};

// Estrae URL dei video dai paragraphs di tipo "paragraph--videos"
export const mapVideosFromParagraphs = (fieldParagraphs: any[]) => {
  return (
    fieldParagraphs
      ?.filter((paragraph) => paragraph.type === 'paragraph--videos')
      ?.flatMap((paragraph) => paragraph.field_medias?.map((media: any) => media.field_media_oembed_video)) || []
  );
};

// Estrae la description
export const mapDescriptionFromParagraphs = (fieldParagraphs: any[]) => {
  const richTextParagraph = fieldParagraphs?.find((paragraph) => paragraph.type === 'paragraph--rich_text');
  return richTextParagraph?.field_description?.processed || '';
};
