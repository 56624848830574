import { Trans } from 'next-i18next';
import { Controller } from 'react-hook-form';

import theme from '@mui/theme';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import CustomButton from '@atoms/CustomButton/CustomButton';
import { CookieSettingsGroupProps } from '@components/organisms/CookiesManager/CookieSettings/components/CookieSettingsGroup/CookieSettingsGroup.props';

const CookieSettingsGroup = (props: CookieSettingsGroupProps) => {
  const { title, description, items } = props;

  return (
    <Box>
      <Typography component="h3" variant="overlineMono">
        {title}
      </Typography>

      <Typography mt={theme.spacing(8)} component="p" variant="bodyS">
        {description}
      </Typography>

      <Box mt={theme.spacing(16)} display="flex" flexDirection="column" gap={theme.spacing(16)}>
        {items.map((item, index) => (
          <Box
            key={index}
            border=".1rem solid"
            borderColor="secondary.200"
            alignItems="center"
            display="flex"
            justifyContent="space-between"
            gap={theme.spacing(30)}
            padding={theme.spacing(20, 30)}
          >
            <Box>
              <Typography component="h4" variant="subtitleBold">
                {item.title}
              </Typography>
              <Typography component="p" variant="bodyS">
                <Trans i18nKey={item.description || `cookieSettings.${item.name}.description`}>
                  x
                  <CustomButton
                    href={item.href}
                    sx={{ verticalAlign: 'baseline' }}
                    target="_blank"
                    variant="textLink"
                    color="textLink"
                  />
                </Trans>
              </Typography>
            </Box>
            <Box>
              <Controller
                name={item.name}
                render={({ field: { onChange, value } }) => (
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Switch
                          disabled={item.disabled}
                          checked={value}
                          onChange={(ev, checked) => onChange(checked)}
                        />
                      }
                      label={
                        <Typography color={value ? 'primary.main' : 'secondary.900'} variant="bodySBold">
                          {value ? 'ON' : 'OFF'}
                        </Typography>
                      }
                    />
                  </FormControl>
                )}
              />
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default CookieSettingsGroup;
