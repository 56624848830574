import { forwardRef, PropsWithChildren } from 'react';
import Box from '@mui/material/Box';

const CookieSettingsContainer = forwardRef(function CookieSettingsContainer(props: PropsWithChildren, ref) {
  return (
    <Box ref={ref} bgcolor="common.white" tabIndex={-1} width="100%" height="100%">
      {props.children}
    </Box>
  );
});

export default CookieSettingsContainer;
