import Modal from '@mui/material/Modal';
import Portal from '@mui/material/Portal';
import { FC, useContext } from 'react';
import { AppContextModel } from '@model/types';
import { AppContext } from '@store/app-context';
import CookieSettingsHeader from '@components/organisms/CookiesManager/CookieSettings/components/CookieSettingsHeader';
import CookieSettingsBody from '@components/organisms/CookiesManager/CookieSettings/components/CookieSettingsBody';
import CookieSettingsFooter from '@components/organisms/CookiesManager/CookieSettings/components/CookieSettingsFooter';
import CookieSettingsContainer from '@components/organisms/CookiesManager/CookieSettings/components/CookieSettingsContainer';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import Cookies from 'js-cookie';
import { runCookieScripts } from '@utils/functions/run-cookie-scripts';
import { useStyles } from './styles';
import { Dialog } from '@mui/material';

const COOKIE_TYPES = ['general', 'matomo', 'facebook', 'twitter', 'youtube', 'linkedin', 'article'] as const;
type CookieType = (typeof COOKIE_TYPES)[number];
type CookieSettingsForm = Record<CookieType, boolean>;

type Props = {
  closeSettings: () => void;
  closeBanner: () => void;
  isOpen: boolean;
};

const CookieSettings: FC<Props> = ({ closeBanner, closeSettings, isOpen }) => {
  const styles = useStyles();

  const form = useForm<CookieSettingsForm>({
    defaultValues: {
      general: true,
      matomo: true,
      facebook: false,
      twitter: false,
      youtube: false,
      linkedin: false,
      article: false,
    },
  });

  const submitHandler: SubmitHandler<CookieSettingsForm> = (formData) => {
    for (let i = 0; i < COOKIE_TYPES.length; i++) {
      const cookie = COOKIE_TYPES[i];
      if (!formData?.[cookie]) continue;
      Cookies.set(`${cookie}_cookie_consent`, '', { expires: 30 });
    }

    closeSettings();
    closeBanner();

    runCookieScripts();
  };

  return (
    <Portal>
      <FormProvider {...form}>
        <Dialog fullScreen sx={styles.modal} disableAutoFocus open={isOpen} onClose={closeSettings}>
          <form onSubmit={form.handleSubmit(submitHandler)}>
            <CookieSettingsContainer>
              <CookieSettingsHeader />
              <CookieSettingsBody />
              <CookieSettingsFooter />
            </CookieSettingsContainer>
          </form>
        </Dialog>
      </FormProvider>
    </Portal>
  );
};

export default CookieSettings;
