import { FC, useState } from 'react';
import dynamic from 'next/dynamic';
import Cookies from 'js-cookie';
import CookieSettings from '@components/organisms/CookiesManager/CookieSettings/CookieSettings';
import CookiesButton from '@components/organisms/CookiesManager/CookiesButton/CookiesButton';

const CookieBanner = dynamic(() => import('@components/organisms/CookiesManager/CookieBanner/CookieBanner'), {
  ssr: false,
});

const CookiesManager: FC = () => {
  const generalCookieConsent = Cookies.get('general_cookie_consent') !== '';
  const [isCookieBannerOpen, setIsCookieBannerOpen] = useState(!!generalCookieConsent);
  const [isCookieSettingsModalOpen, setIsCookieSettingsModalOpen] = useState(false);

  const openSettings = () => {
    setIsCookieSettingsModalOpen(true);
  };
  const closeSettings = () => {
    setIsCookieSettingsModalOpen(false);
  };

  const closeBanner = () => {
    setIsCookieBannerOpen(false);
  };

  const acceptCookies = () => {
    Cookies.set('general_cookie_consent', '', { expires: 30 });
    Cookies.set('matomo_cookie_consent', '', { expires: 365 });

    closeBanner();
  };

  return (
    <>
      <CookieBanner isOpen={isCookieBannerOpen} acceptCookies={acceptCookies} openSettings={openSettings} />
      <CookieSettings isOpen={isCookieSettingsModalOpen} closeSettings={closeSettings} closeBanner={closeBanner} />
      <CookiesButton openSettings={openSettings} />
    </>
  );
};

export default CookiesManager;
