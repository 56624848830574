import React, { useMemo } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { CTX_TAX_MAP, EXCLUDED_PAGES_LIST, MATOMO_SRC_MAP } from '@utils/constants/constants';
import getHeaderStockPriceSrc from '@utils/functions/getHeaderStockPriceSrc';

interface MetaTagsProps {
  node: any;
  langData: { lang: string; path: string }[];
}

const matomoSrc = MATOMO_SRC_MAP?.[process.env.NEXT_PUBLIC_MULTISITE_CONTEXT_TAX]
  ? MATOMO_SRC_MAP[process.env.NEXT_PUBLIC_MULTISITE_CONTEXT_TAX]
  : MATOMO_SRC_MAP[CTX_TAX_MAP.GLOBAL];

const MetaTags = ({ node, langData }: MetaTagsProps) => {
  const router = useRouter();
  // const metaTitle = node?.field_meta_tags?.title ?? node?.title;
  const metaTitle =
    node?.field_meta_tags?.title ||
    (node.metatag.find((tag) => tag.attributes.name == 'title')?.attributes.content ?? node.title);
  const metaDescription =
    node?.field_meta_tags?.description ||
    (node.metatag.find((tag) => tag.attributes.name == 'description')?.attributes.content ?? '');

  const metaTagSet = new Set();
  const defaultMetaTags = node?.metatag?.reduce((tags, tag) => {
    const tagKey = JSON.stringify(tag);
    if (!metaTagSet.has(tagKey) && tag.attributes?.rel !== 'canonical') {
      metaTagSet.add(tagKey);
      tags.push(React.createElement(tag.tag, { ...tag.attributes, key: tagKey }));
    }
    return tags;
  }, []);

  const isNoIndex = EXCLUDED_PAGES_LIST.some((excludedPath) => excludedPath === router.asPath);

  const getCanonicalURL = () => {
    if (router.asPath === '/' && router.defaultLocale === router.locale) {
      return process.env.NEXT_PUBLIC_BASE_URL;
    } else {
      const {
        query: { slug },
      } = router;
      const withoutParamsPath = slug && Array.isArray(slug) ? slug.join('/') : slug;
      const formattedSlugPath = withoutParamsPath ? `/${withoutParamsPath}/` : '';
      const path = router.asPath.includes('?') ? formattedSlugPath : router.asPath;
      return `${process.env.NEXT_PUBLIC_BASE_URL}/${router.locale}${path}`;
    }
  };

  const canonicalURL = getCanonicalURL();

  // https://developers.google.com/search/docs/specialty/international/localized-versions?hl=en#html
  /** Alternate page links */
  const alternateLinks = router.locales.map((altLocale, i) => {
    const altLocaleData = langData.find((data) => data.lang === altLocale);
    if (!altLocaleData) return <></>;

    const path = altLocaleData?.path ? altLocaleData?.path.replace(/\/\//g, '/') : `${altLocale}${router.asPath}`;
    const alternateHref = `${process.env.NEXT_PUBLIC_BASE_URL}/${path}/`;
    const isItHome = alternateHref === `${process.env.NEXT_PUBLIC_BASE_URL}/it/`;

    return (
      <link
        key={`${i}-${altLocale}`}
        rel="alternate"
        hrefLang={altLocale}
        href={isItHome ? process.env.NEXT_PUBLIC_BASE_URL : alternateHref}
      />
    );
  });
  /** X-default alternate page link  */
  const xDefaultLink = useMemo(() => {
    const enLocaleData = langData.find((data) => data.lang === 'en');
    if (!enLocaleData) return <></>;

    const path = enLocaleData?.path ? enLocaleData?.path.replace(/\/\//g, '/') : `en${router.asPath}`;

    return <link rel="alternate" hrefLang="x-default" href={`${process.env.NEXT_PUBLIC_BASE_URL}/${path}/`} />;
  }, [langData, router.asPath]);

  const headerStockPriceSrc = getHeaderStockPriceSrc(router.locale);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=5" />
        {isNoIndex ? (
          <meta name="robots" content="noindex" />
        ) : (
          <meta name="robots" content={node.field_meta_tags?.robots ?? 'index, follow'} />
        )}
        <title>{metaTitle}</title>

        {metaDescription && <meta name="description" content={metaDescription} />}
        <link rel="canonical" href={canonicalURL} />
        {defaultMetaTags}

        <meta property="og:site_name" content="webuildgroup" />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content="https://www.webuildgroup.com/assets/images/01_share.jpg" />

        <link rel="manifest" href="/manifest.json" crossOrigin="use-credentials" />
        <link rel="apple-touch-icon" href="/logo-96x96.png" />
        <meta name="theme-color" content="#db002f" />
        <meta name="apple-mobile-web-app-status-bar" content="#db002f" />

        <link rel="preconnect" href={headerStockPriceSrc} />
        <link rel="preconnect" href={matomoSrc} />

        {alternateLinks}
        {xDefaultLink}
      </Head>
    </>
  );
};

export default MetaTags;
