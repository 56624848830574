import { useMemo } from 'react';
import theme from '@mui/theme';

export const useStyles = () => {
  const buttonWrapper = useMemo(
    () => ({
      display: 'inline-flex',
      flexFlow: 'column nowrap',
      justifyContent: 'flex-end',
      position: 'fixed',
      zIndex: 100,
      transition: 'border-radius 200ms ease, right: 200ms ease, bottom 200ms ease',
      backdropFilter: `blur(${theme.spacing(2)})`,
      backgroundColor: theme.palette.alphaBlack[50],
      padding: theme.spacing(16),
      [theme.breakpoints.up('xs')]: {
        width: 'auto',
        borderRadius: theme.borderRadius.surface,
        left: theme.spacing(8),
        bottom: theme.spacing(8),
      },
      [theme.breakpoints.up('lg')]: {
        width: 'auto',
        borderRadius: theme.borderRadius.surface,
        left: theme.spacing(16),
        bottom: theme.spacing(16),
      },
    }),
    []
  );

  const button = useMemo(
    () => ({ ml: 'auto', width: 'fit-content', minWidth: 0, borderRadius: theme.spacing(48) }),
    []
  );

  return {
    buttonWrapper,
    button,
  };
};
