import Container, { ContainerProps } from '@mui/material/Container';
import { forwardRef, MutableRefObject } from 'react';

const GridContainer = forwardRef(function GridContainer(props: ContainerProps, ref: MutableRefObject<HTMLDivElement>) {
  return (
    <Container
      {...props}
      ref={ref}
      maxWidth={false}
      sx={{ ...props.sx, px: { xs: 22, md: 44, lg: 64, xl: 88, xxl: 120 } }}
    />
  );
});

export default GridContainer;
