import theme from '@mui/theme';
import CustomButton from '@atoms/CustomButton/CustomButton';
import Box from '@mui/material/Box';
import { useTranslation } from 'next-i18next';

const CookieSettingsFooter = () => {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent={{ xs: 'center', md: 'space-between' }}
      flexWrap={{ xs: 'wrap', md: 'nowrap' }}
      padding={theme.spacing(32, 60)}
      gap={theme.spacing(20)}
      borderTop=".1rem solid"
      borderColor="secondary.200"
      position="sticky"
      bottom={0}
      right={0}
      bgcolor="white"
      maxHeight={336}
    >
      <CustomButton variant="textLink" color="textLink" href={t('cookiePrivacyHref')}>
        {t('cookieSettings.privacy')}
      </CustomButton>
      <CustomButton type="submit" variant="small" color="primary">
        {t('cookieSettings.save')}
      </CustomButton>
    </Box>
  );
};

export default CookieSettingsFooter;
