import { useCallback } from 'react';
import { Theme } from '@mui/material';

export const useStyles = () => {
  const modal = useCallback(
    (theme: Theme) => ({
      '& .MuiDialog-container .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded': {
        overflow: 'hidden',
        maxWidth: 'none',
        width: '100%',
        height: '100%',
        margin: 0,
        [theme.breakpoints.up('sm')]: {
          maxWidth: theme.spacing(600),
          margin: theme.spacing(32),
          height: 'auto',
        },
        [theme.breakpoints.up('md')]: {
          maxWidth: theme.spacing(800),
        },
        [theme.breakpoints.up('lg')]: {
          maxWidth: theme.spacing(1000),
        },
      },
    }),
    []
  );

  return { modal };
};
