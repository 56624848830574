const getHeaderStockPriceSrc = (locale: 'it' | 'en' | string) => {
  switch (locale) {
    case 'en': {
      return process.env.NEXT_PUBLIC_DORA_URL_EN;
    }
    case 'it':
    default: {
      return process.env.NEXT_PUBLIC_DORA_URL_IT;
    }
  }
};

export default getHeaderStockPriceSrc;
