import React, { FC, useContext } from 'react';
import dynamic from 'next/dynamic';
// import HeaderDesktop from '@organisms/Header/AltHeader/HeaderContent/HeaderDesktop/HeaderDesktop';
// import HeaderTablet from '@organisms/Header/AltHeader/HeaderContent/HeaderTablet/HeaderTablet';
// import HeaderMobile from '@organisms/Header/AltHeader/HeaderContent/HeaderMobile/HeaderMobile';
import { HeaderContext } from '../Header.context';

const HeaderDesktop = dynamic(() => import('@organisms/Header/AltHeader/HeaderContent/HeaderDesktop/HeaderDesktop'));
const HeaderTablet = dynamic(() => import('@organisms/Header/AltHeader/HeaderContent/HeaderTablet/HeaderTablet'));
const HeaderMobile = dynamic(() => import('@organisms/Header/AltHeader/HeaderContent/HeaderMobile/HeaderMobile'));

const HeaderContent: FC = () => {
  const { showDesktopHeader, showTabletHeader, showMobileHeader } = useContext(HeaderContext);

  return (
    <>
      {showDesktopHeader ? <HeaderDesktop show={showDesktopHeader} /> : null}
      {showTabletHeader ? <HeaderTablet show={showTabletHeader} /> : null}
      {showMobileHeader ? <HeaderMobile show={showMobileHeader} /> : null}
    </>
  );
};

export default HeaderContent;
