import { LinkModel } from '@model/link.model';

export const getLinkData = (item): LinkModel => {
  return {
    label: item?.title,
    url: item.url,
    isExternal: item.options.external,
    rel: item?.options.attributes?.rel,
    target: item?.options?.attributes?.target,
  };
};
