import Button from '@mui/material/Button';
import Link from 'next/link';
import { CustomButtonProps } from './CustomButton.props';
import { useRouter } from 'next/router';
import { PropsWithChildren, useMemo } from 'react';
import styles from './CustomButton.module.scss';

const CustomButton = (props: PropsWithChildren<CustomButtonProps>) => {
  const { asPath, locale } = useRouter();

  const linkClassName = useMemo(() => {
    let classes = `buttonText ${props.buttonTextClassName || ''}`;

    if (!['/', `/${locale}`].includes(props.href) && `/${locale}${asPath}`.includes(props.href)) {
      classes += ` ${styles.ActiveLink}`;
    }

    return classes;
  }, [asPath, locale, props.href, props.buttonTextClassName]);

  if (props.component === 'a') {
    const {
      target,
      href,
      component,
      componentRef,
      buttonTextClassName = '',
      text,
      variant,
      color,
      className,
      external,
      ...otherProps
    } = props;

    if (!href) return null;

    if (external) {
      return (
        <Button
          {...otherProps}
          color={color}
          className={className}
          href={href}
          target={target}
          ref={componentRef}
          component={component}
          variant={variant}
        >
          <span className={`buttonText ${buttonTextClassName}`}>
            {text}
            {props.children}
          </span>
        </Button>
      );
    }

    return (
      <Button
        {...otherProps}
        color={color}
        component={Link}
        prefetch={false}
        href={href}
        ref={componentRef}
        className={className}
        variant={variant}
        target={target}
      >
        <span className={linkClassName}>
          {text}
          {props.children}
        </span>
      </Button>
    );
  }

  const { variant, color, className, componentRef, buttonTextClassName = '', text, external, ...otherProps } = props;

  return (
    <Button {...otherProps} color={color} ref={componentRef} className={className} variant={variant}>
      <span className={`buttonText ${buttonTextClassName}`}>
        {text}
        {props.children}
      </span>
    </Button>
  );
};

export default CustomButton;
