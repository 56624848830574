import theme from '@mui/theme';
import CookieSettingsGroup from '@components/organisms/CookiesManager/CookieSettings/components/CookieSettingsGroup/CookieSettingsGroup';
import Box from '@mui/material/Box';
import { useTranslation } from 'next-i18next';

const FOOTER_HEIGHT = 145;

const CookieSettingsBody = () => {
  const { t } = useTranslation();

  const requiredCookies = [
    {
      name: 'general',
      title: 'WebuildGroup',
      disabled: true,
      href: `${t('cookiePrivacyHref')}?scrollto=technical-cookies`,
    },
    {
      name: 'matomo',
      title: 'Matomo',
      disabled: true,
      href: `${t('cookiePrivacyHref')}?scrollto=third-party-cookies`,
    },
  ];

  const thirdPartyCookies = [
    {
      name: 'facebook',
      title: 'Facebook',
      href: `${t('cookiePrivacyHref')}?scrollto=third-party-cookies`,
      description: 'cookieSettings.socialDescription',
    },
    {
      name: 'twitter',
      title: 'Twitter',
      href: `${t('cookiePrivacyHref')}?scrollto=third-party-cookies`,
      description: 'cookieSettings.socialDescription',
    },
    {
      name: 'youtube',
      title: 'YouTube',
      href: `${t('cookiePrivacyHref')}?scrollto=third-party-cookies`,
      description: 'cookieSettings.socialDescription',
    },
    {
      name: 'linkedin',
      title: 'LinkedIn',
      href: `${t('cookiePrivacyHref')}?scrollto=third-party-cookies`,
      description: 'cookieSettings.socialDescription',
    },
  ];

  const personalizationCookies = [
    {
      name: 'article',
      title: t('cookieSettings.article.title'),
      description: 'cookieSettings.article.description',
      href: `${t('cookiePrivacyHref')}?scrollto=technical-cookies`,
    },
  ];

  return (
    <Box
      padding={{ xs: theme.spacing(30), md: theme.spacing(40, 60) }}
      display="flex"
      flexDirection="column"
      overflow="auto"
      maxHeight={theme.spacing(500)}
      gap={theme.spacing(32)}
    >
      <CookieSettingsGroup
        title={t('cookieSettings.requiredCookies.title')}
        description={t('cookieSettings.requiredCookies.description')}
        items={requiredCookies}
      />

      <CookieSettingsGroup
        title={t('cookieSettings.thirdPartyCookies.title')}
        description={t('cookieSettings.thirdPartyCookies.description')}
        items={thirdPartyCookies}
      />

      <CookieSettingsGroup
        title={t('cookieSettings.personalizationCookies.title')}
        description={t('cookieSettings.personalizationCookies.description')}
        items={personalizationCookies}
      />

      <Box width="100%" minHeight={FOOTER_HEIGHT} />
    </Box>
  );
};

export default CookieSettingsBody;
