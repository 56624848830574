import { createContext, FC, PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';
import theme from '@mui/theme';
import { LangData } from '@model/types';
import { HeaderModel } from '@model/header.model';
import { DrupalNode } from 'next-drupal';
import { useRouter } from 'next/router';
import useHeaderMapper from '@organisms/Header/AltHeader/useHeaderMapper';
import useMediaQuery from '@mui/material/useMediaQuery';

type VoidFun = () => void;
const voidFun: VoidFun = () => null;

type HeaderContextValue = {
  langData: { path: string; lang: string }[];
  isSticky: boolean;
  firstEcosystemButtonRef: React.MutableRefObject<HTMLAnchorElement> | null;
  headerData: HeaderModel | null;
  isEcosystemMenuOpen: boolean;
  setIsEcosystemMenuOpen: React.Dispatch<React.SetStateAction<boolean>> | VoidFun;
  isSearchMenuOpen: boolean;
  setIsSearchMenuOpen: React.Dispatch<React.SetStateAction<boolean>> | VoidFun;
  isMobileMenuOpen: boolean;
  setIsMobileMenuOpen: React.Dispatch<React.SetStateAction<boolean>> | VoidFun;
  onEcosystemButtonClick: VoidFun;
  onCloseSearchMenu: VoidFun;
  onCloseEcosystemMenu: VoidFun;
  onSearchButtonClick: VoidFun;
  onMobileMenuButtonClick: VoidFun;
  showDesktopHeader: boolean;
  showTabletHeader: boolean;
  showMobileHeader: boolean;
};

export const HeaderContext = createContext<HeaderContextValue>({
  langData: [],
  isSticky: false,
  firstEcosystemButtonRef: null,
  headerData: null,
  isEcosystemMenuOpen: false,
  setIsEcosystemMenuOpen: voidFun,
  isSearchMenuOpen: false,
  setIsSearchMenuOpen: voidFun,
  isMobileMenuOpen: false,
  setIsMobileMenuOpen: voidFun,
  onEcosystemButtonClick: voidFun,
  onCloseSearchMenu: voidFun,
  onCloseEcosystemMenu: voidFun,
  onSearchButtonClick: voidFun,
  onMobileMenuButtonClick: voidFun,
  showDesktopHeader: false,
  showTabletHeader: false,
  showMobileHeader: false,
});

type Props = PropsWithChildren<{ backendData: any; node?: DrupalNode; langData?: LangData[] }>;

export const HeaderContextProvider: FC<Props> = ({ children, backendData, node, langData }) => {
  const headerData = useHeaderMapper({ backendData, node });
  const firstEcosystemButtonRef = useRef<HTMLAnchorElement>(null);
  const [isSticky, setIsSticky] = useState(false);
  const [isEcosystemMenuOpen, setIsEcosystemMenuOpen] = useState(false);
  const [isSearchMenuOpen, setIsSearchMenuOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const { events } = useRouter();

  useEffect(() => {
    setIsEcosystemMenuOpen(false);
    setIsSearchMenuOpen(false);
  }, [isSticky]);

  const onEcosystemButtonClick = useCallback(() => {
    setIsEcosystemMenuOpen((current) => !current);
    setIsSearchMenuOpen(false);
    if (!firstEcosystemButtonRef.current || isEcosystemMenuOpen) return;
    firstEcosystemButtonRef.current?.focus();
  }, [firstEcosystemButtonRef, isEcosystemMenuOpen]);

  const onCloseSearchMenu = () => {
    setIsSearchMenuOpen(false);
  };
  const onCloseEcosystemMenu = () => {
    setIsEcosystemMenuOpen(false);
  };
  const onSearchButtonClick = () => {
    setIsEcosystemMenuOpen(false);
    setIsSearchMenuOpen((current) => !current);
  };
  const onMobileMenuButtonClick = () => {
    setIsMobileMenuOpen((prev) => !prev);
  };

  const openStickyMenuHandler = () => {
    setIsSticky(window.scrollY > window.innerHeight);
  };

  const onRouteChangeComplete = () => {
    setIsSearchMenuOpen(false);
  };

  useEffect(() => {
    events.on('routeChangeComplete', onRouteChangeComplete);
    return () => {
      events.off('routeChangeComplete', onRouteChangeComplete);
    };
  }, [events]);

  useEffect(() => {
    window.addEventListener('scroll', openStickyMenuHandler);
    return () => {
      window.removeEventListener('scroll', openStickyMenuHandler);
    };
  }, []);

  const showDesktopHeader = useMediaQuery(`(min-width: ${theme.breakpoints.values.xlg}px)`);
  const showTabletHeader = useMediaQuery(`(min-width: ${theme.breakpoints.values.sm}px)`) && !showDesktopHeader;
  const showMobileHeader = !showTabletHeader && !showDesktopHeader;

  const value = {
    headerData,
    firstEcosystemButtonRef,
    isSticky,
    langData,
    isEcosystemMenuOpen,
    setIsEcosystemMenuOpen,
    isSearchMenuOpen,
    setIsSearchMenuOpen,
    isMobileMenuOpen,
    setIsMobileMenuOpen,
    onEcosystemButtonClick,
    onCloseSearchMenu,
    onCloseEcosystemMenu,
    onSearchButtonClick,
    onMobileMenuButtonClick,
    showDesktopHeader,
    showTabletHeader,
    showMobileHeader,
  };

  return <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>;
};
